<template>
  <div class="card card-primary card-outline">
    <div class="card-header">
      <h5 class="card-title m-0"><i class="fa fa-list"></i> List Agent</h5>
    </div>
    <div class="card-body">
      <!-- if request api failed -->
      <div
        v-if="!xhr.agents.isBusy && !xhr.agents.success"
        class="alert alert-danger"
      >
        {{ xhr.agents.record }}
      </div>
      <div v-else>
        <!--filter & length-->
        <div class="d-flex justify-content-between">
          <div class="mb-2">
            <b-form-select
              :options="[5, 10, 15, 20]"
              :value="5"
              v-model="xhr.agents.perPage"
            ></b-form-select>
          </div>
          <div>
            <b-form-input
              type="search"
              placeholder="Search"
              v-model="xhr.agents.search"
            ></b-form-input>
          </div>
        </div>

        <b-table
          id="my-table"
          striped
          hover
          show-empty
          :busy="xhr.agents.isBusy"
          :items="xhr.agents.record"
          :fields="xhr.agents.field"
          :per-page="xhr.agents.perPage"
          :filter="xhr.agents.search"
          :current-page="xhr.agents.currentPage"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="text-center text-warning my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong> Loading...</strong>
            </div>
          </template>

          <!-- department custom-->
          <template #cell(dept_name)="data">
            <span v-html="badgeDept(data.item.dept_name)"></span>
          </template>
          <!-- status custom-->
          <template #cell(status)="data">
            <span v-if="data.item.status === 'on'" class="badge badge-success"
              >ON</span
            >
            <span v-if="data.item.status === 'away'" class="badge badge-warning"
              >AWAY</span
            >
            <span v-if="data.item.status === 'off'" class="badge badge-danger"
              >OFF</span
            >
          </template>
        </b-table>

        <!-- pagination -->
        <b-pagination
          v-model="xhr.agents.currentPage"
          :total-rows="xhr.agents.totalRows"
          :per-page="xhr.agents.perPage"
          aria-controls="my-table"
          align="right"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { request } from "@/common/mixins/mix_helper";

export default {
  name: "ListAgent",
  mixins: [request],
  data() {
    return {
      xhr: {
        agents: {
          record: [],
          success: false,
          field: [
            {
              key: "id",
              label: "ID",
              sortable: true,
            },
            {
              key: "nama",
              label: "Agent Name",
              sortable: true,
            },
            {
              key: "email",
              label: "Email",
              sortable: true,
            },
            {
              key: "jabatan",
              label: "Position",
              sortable: true,
            },
            {
              key: "dept_name",
              label: "Department",
              sortable: false,
            },
            {
              key: "status",
              label: "Status",
              sortable: false,
            },
          ],
          perPage: 5,
          currentPage: 1,
          search: null,
          totalRows: 1,
          isBusy: true,
        },
      },
      table: {
        field: [
          {
            key: "id",
            label: "ID",
            sortable: true,
          },
          {
            key: "nama",
            label: "Agent Name",
            sortable: true,
          },
          {
            key: "email",
            label: "Email",
            sortable: true,
          },
          {
            key: "jabatan",
            label: "Position",
            sortable: true,
          },
          {
            key: "dept_name",
            label: "Department",
            sortable: true,
          },
          {
            key: "status",
            label: "Status",
            sortable: true,
          },
        ],
        record: [],
      },
      items: [],
    };
  },
  methods: {
    /**
     * --------------------------------------------------------------
     * request all agent
     * --------------------------------------------------------------
     */
    getAgent: function () {
      this.API.post(this.URL.agent.profile, { result: "list" })
        .then(({ data }) => {
          this.xhr.agents.record = data.data;
          this.xhr.agents.success = true;
          this.xhr.agents.totalRows = data.data.length;
        })
        .catch((err) => {
          this.xhr.agents.record = err;
        })
        .finally(() => {
          this.xhr.agents.isBusy = false;
        });
    },

    /**
     * --------------------------------------------------------------
     * for set color of badge between department
     * --------------------------------------------------------------
     */
    badgeDept: function (name) {
      let dept = name.toLowerCase();
      let badge = `<span class="badge badge-info">${name}</span>`;
      if (dept === "billing") {
        badge = `<span class="badge badge-primary">${name}</span>`;
      } else if (dept === "technical support") {
        badge = `<span class="badge badge-secondary">${name}</span>`;
      }
      return badge;
    },

    /**
     * --------------------------------------------------------------
     * trigger ketika melakukan pencarian
     * --------------------------------------------------------------
     */
    onFiltered(filteredItems) {
      this.xhr.agents.totalRows = filteredItems.length;
      this.xhr.agents.currentPage = 1;
    },
  },
  mounted() {
    this.getAgent();
    this.$store.dispatch("breadcrumb", {
      title: "Agent Page",
      path: "List Agen",
    });
    this.$store.dispatch("activeLink", this.$route.path);
  },
};
</script>

<style scoped></style>
